import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ProjectsPage from '../templates/projects.js';
export const _frontmatter = {
  "title": "Projects"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ProjectsSection = makeShortcode("ProjectsSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ProjectsPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Section size='large' style={{
      paddingBottom: 0
    }} mdxType="Section">
      <Container mdxType="Container">
        <Row mdxType="Row"><Column sm={40} mdxType="Column">
            <h1>{`Projects`}</h1>
            <p>{`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`}</p>
          </Column></Row>
      </Container>
    </Section>
    <ProjectsSection mdxType="ProjectsSection" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      